import React, { Component } from 'react';
import myData from './raised.json';
const scrapeAmount = myData.amount;

const WhereSection = () => {
  return (
    <>
      <div className="col-lg-8">
        <div className="text-start my-5">
          <h2 className="display-5 fw-bolder"><span className="d-inline">Where to donate.</span></h2>
          <p className="lead fw-light mb-1">Please consider donating.</p>
          <p>We're doing this event to not only remember our friend, Billy, but to also raise
            money for colon cancer awareness. We have a memorial page <a
              href="https://donate.coloncancercoalition.org/memorials/BillyKametz" target="_blank">here</a> where anyone can donate to the cause. Last year we earned over <strong>$16,000</strong> (surpassing our goal of $12,000) and we'd love to meet or exceed that if we can this year. Any amount received is greatly appreciated!</p>
        </div>
      </div>
      <div className="col-lg-4 d-flex flex-column align-content-center">
        <div className="total">
          <span>{scrapeAmount ? scrapeAmount : '-'}</span>
        </div>
        <a href="https://donate.coloncancercoalition.org/memorials/BillyKametz" target="_blank" className="btn btn-primary d-inline-block">Donate</a>
      </div>
    </>
  )
}

export default WhereSection;
import React, { Component, useState, useEffect } from 'react';
import starGraphic from '../assets/star.png';

const Navigation = () => {

    const handleScroll = () => {
        const position = window.pageYOffset;
        const header = document.querySelector(".navbar");
        const scrollChange = 1;

        const add_class_on_scroll = () => header.classList.add("scrolled");
        const remove_class_on_scroll = () => header.classList.remove("scrolled");
        if (position >= scrollChange) { add_class_on_scroll() }
        else { remove_class_on_scroll() }
    };
    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    document.addEventListener('click', (el) => {
        var _opened = document.querySelector(".navbar-collapse").classList.contains("show");
        if (_opened === true && !el.target.classList.contains('navbar-toggler-icon')) {
            document.querySelector("button.navbar-toggler").click();
        }
    });

    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-white py-3 navbar-fixed-top">
            <div className="container">
                <a className="navbar-brand" href="index.html"><img src={starGraphic} alt="Star" /><span
                    className="fw-bolder">Billy Kametz 120 Star Challenge</span></a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                    aria-label="Toggle navigation"><span className="navbar-toggler-icon"></span></button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav ms-auto mb-2 mb-lg-0 small fw-bolder">
                        <li className="nav-item"><a className="nav-link" href="#whatSection">What this is.</a></li>
                        <li className="nav-item"><a className="nav-link" href="#rulesSection">Rules.</a></li>
                        <li className="nav-item"><a className="nav-link" href="#whereSection">Where to donate.</a></li>
                        <li className="nav-item"><a className="nav-link" href="#streamSection">See the livestream.</a></li>
                        <li className="nav-item"><a className="nav-link" href="#moreSection">Colon Cancer.</a></li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default Navigation;
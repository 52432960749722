import Navigation from './components/Navigation.js';
import Header from './components/Header.js';
import WhatSection from './components/WhatSection.js';
import RulesSection from './components/RulesSection.js';
import WhereSection from './components/WhereSection.js';
import LivestreamSection from './components/LivestreamSection.js';
import MoreSection from './components/MoreSection.js';
import ThanksSection from './components/ThankYou.js';
import Footer from './components/Footer.js';

const App = () => {
  return (
    <div>
      <Navigation />
      <Header />
      <main data-bs-spy="scroll" data-bs-target="#navbar-scroll-area">
        
        <section className="bg-quinary-faded py-5" id="whatSection">
              <div className="container ">
                  <div className="row ">
                      <div className="col-sm-10 offset-sm-1">
                          <div className="text-start my-5">
                            <WhatSection />
                          </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="py-5" id="rulesSection">
            <div className="container ">
                <div className="row ">
                    <div className="col-sm-10 offset-sm-1">
                        <div className="my-5 text-muted">
                          <RulesSection />
                        </div>
                  </div>
              </div>
          </div>
        </section>

        <section className="bg-quinary-faded py-5" id="whereSection">
            <div className="container ">
                <div className="row ">
                    <div className="col-sm-10 offset-sm-1">
                        <div className="row d-flex align-items-center">
                          <WhereSection />
                      </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="py-5" id="streamSection">
            <div className="container ">
                <div className="row ">
                    <LivestreamSection />
                </div>
            </div>
        </section>

        <section className="bg-quinary-faded py-5" id="moreSection">
            <div className="container ">
                <div className="row ">
                    <div className="col-sm-10 offset-sm-1">
                        <div className="text-start my-5">
                      <MoreSection />
                      </div>
                    </div>
                </div>
            </div>
        </section>
      </main>

      <Footer />
    </div>
  );
}

export default App;

import React, { Component } from 'react';

const MoreSection = () => {
    return (
        <>
            <h2 className="display-5 fw-bolder"><span className="d-inline">More on Colon Cancer.</span></h2>
            <p className="lead fw-light mb-1">Colon cancer is rising rapidly among young people.</p>
            <p>According to the American Cancer Society, the proportion of people diagnosed aged 54 or
                younger nearly doubled from 1995 to 2019. Often, by the time the cancer is detected,
                it's already too late. More than half of all colon cancer diagnoses in 2019 were at
                advanced stages. In 2021, the U.S. Preventive Services Task Force - the body that makes
                recommendations around cancer screenings - lowered the recommended age for colonoscopies
                from 50 to 45. You can help those affected by and at-risk of this disease - the
                second-deadliest cancer - by donating today.</p>
        </>
    )
}

export default MoreSection;
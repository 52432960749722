import React, { Component } from 'react';
import billySocial from '../assets/Billy_Share.jpg';

const Footer = () => {
    const currentYear = new Date().getFullYear();

    return (
        <footer className="bg-dark py-4 mt-auto text-light">
            <div className="container px-5">
                <div className="row">
                    <div className="col-xl-10 offset-xl-1">
                        <div className="row align-items-center text-center flex-column flex-sm-row">
                            <div className="col-lg-6">
                                <div className="small m-0">Thank you for joining, supporting, and remembering our friend :)</div>
                            </div>
                            <div className="col-lg-6 mt-3 mt-lg-0">
                                <div className="small m-0">Copyright &copy; {currentYear} - Billy Kametz 120 Star Challenge</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className='text-center smaller'>
                        <p>Sharing to social media? <a href={billySocial} target="_blank">Here is a graphic if needed.</a> Questions? Email <a href="mailto:hello@120starchallenge.com">Hello@120StarChallenge.com</a></p>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;
import React, { Component } from 'react';

const RulesSection = () => {
    return (
        <>
            <h2 className="display-5 fw-bolder text-quinary"><span className="d-inline">Rules.</span></h2>
            <p className="lead mb-1">It is a competition, after all.</p>
            <hr className="mt-3 mb-3" />
            <div className="row mt-2">
                <div className="col-lg-6">
                    <h3>It's every person for themselves this time. We're seeing who can get the most stars in a variety of ways.</h3>
                    <p>This year, we decided to mix it up and play some other games that we loved to play with Billy. We have many fond memories playing all these games with each other (despite screaming expletives at each other while we did).</p>
                    <p>Each game has different star values which we are going to diligently track while we play. This time, everyone is on their own for this marathon of gaming. As the day progresses, we'll keep a running total of stars for each participant with a champion declared at the end based on star total.</p>
                    <p>We're warming up the thumbs with a Mario NES Speedrun. Each player will see if they can beat the game the fastest (warps allowed) or whoever can get the farthest in 10 minutes. The fastest time gets the most stars.</p>
                    <p>The next game will be 6 cups of Mario Kart where we'll all be racing together. The player's placement at the end of each cup will determine their star winnings.</p>
                    <p>Next up, Mario Party. 2 mini-games in particular were very popular when we'd all play: Shy Guy Says and Bumper Balls. We're going to do a round-robin-esque style with combinations of 4 players where a player can earn 1 star per win in each mini-game round.</p>
                    <p>Finally, we're capping off the night with Super Smash Brothers Ultimate - a Billy Favorite. We'll start with a seeding tournament where it's 6 people fighting for position in each round. Once seeding is completed, it's a 1-on-1 bracket tournament to see who is the last person standing. Each round for seeding and the bracket tournament will earn stars based on the player's final place.</p>
                    <p>Once all game rounds have been completed, we'll declare the person who won the most overall stars the winner. Will they be able get to 120 stars?</p>
                    <p className='small'>A footnote - some friends are unable to participate in the entire day's festivities, so we will be doing exhibition rounds of Smash Brothers at some point during the day so we can still enjoy some game time with them :).</p>
                </div>
                <div className="col-lg-6">
                    <h3>Schedule (EST)</h3>
                    <p>Note that times may change depending how long the various game rounds may take.</p>
                    <ul className="schedule">
                        <li><span>10 - 11</span>Mario Speedrun</li>
                        <li><span>11 - 1</span>Mario Kart</li>
                        <li><span>1 - 2</span>Lunch</li>
                        <li><span>2 - 4:30</span>Mario Party</li>
                        <li><span>4:30 - 6</span>Dinner</li>
                        <li><span>6 - done!</span>Super Smash Brothers</li>
                    </ul>
                </div>
            </div>
        </>
    )
}

export default RulesSection;
import React, { Component } from 'react';
import Carousel from './Carousel.js';

const WhatSection = () => {
    return (
        <>
            <h2 className="display-5 fw-bolder"><span className="d-inline">What this is.</span></h2>
            <p className="lead fw-light mb-1">And why we're doing it.</p>
            <p>Last year marked the inaugural Billy Kametz Memorial Fundraiser. A group of friends who grew up with Billy came together for a 12-hour Super Mario 64 charity competition to celebrate his life one year after his untimely death.</p>
            <p>We had no idea how it would go, but thanks to your generosity, we raised more than $16,000 to fight colon cancer! We beat our fundraising goal, and we had so much fun doing it we decided to make it an annual tradition.</p>
            <p>We hope you will join us again - Saturday, May 25 - for another day-long celebration of Billy's life by doing what he loved most: Sitting around in a friend's basement, telling stories, and playing video games.</p>
            <p>This year, we're switching up the format (since the thought of Super Mario 64 is deeply triggering to us now). Instead, we'll be throwing a “Mario Party” of sorts, with each player competing to see who is the best all-around gamer across four Nintendo franchises near and dear to Billy: Super Mario Brothers, Mario Kart, Mario Party, and Super Smash Brothers. </p> 
            <p>Just like last year, we'll be streaming this event live on YouTube and collecting donations to benefit the Colon Cancer Coalition. We're a nostalgic bunch, and you can be sure we'll share some funny, heartfelt, and yes, some embarrassing stories from growing up with Billy. We hope this will be a window into what Billy was really like as a person, from the people who knew him best.</p>
            <p>Another year has passed since we lost our friend, and not a day goes by where we don't think about Billy. Last year was such a cathartic experience and an amazing way to keep his memory alive. We also loved hearing from his fans in the chat, including some who shared their experiences challenging the man himself to Smash Brothers at conventions. 
How did Billy become such a fearsome Star Wolf player? Tune in to get a glimpse of the competitive fires he was forged in with his knucklehead friends!</p>
            <Carousel />
        </>
    )
}

export default WhatSection;
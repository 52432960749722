import React, { Component } from 'react';

const LivestreamSection = () => {
    return (
        <>
            <div className="col-lg-8 text-center">
                <div className="livestream-wrap">
                    <iframe src="https://www.youtube.com/embed/pBobzdkd6b0?si=W97J5lJ1AgN4h571" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>
            </div>
            <div className="col-lg-4">
                <div className="text-muted">
                    <h2 className="display-5 fw-bolder text-quinary"><span className="d-inline">See the 
                        livestream.</span></h2>
                    <p className="lead fw-light mb-1">Watch us play.</p>
                    <p>We'll have the livestream going all day. See it here on the site OR if you want to chat
                        with us while we play, use the chat on the livestream page on youtube. If you're going to share via social media, use the hashtags <strong>#BillyKametz</strong> and <strong>#120StarChallenge</strong>.
                    </p>
                    <p className="fw-light">*Advanced apologies for any swearing. We'll do our best to keep it
                        clean.</p>
                    <p><a href="https://www.youtube.com/channel/UC4S0Jio7negiCSzgppLddwg/live" className="btn btn-primary" role="button" target='_blank'>Livestream on Youtube</a></p>
                    <p><a href="https://www.youtube.com/watch?v=hl4-nOTSF94" className="btn btn-primary" role="button" target='_blank'>Last Year's Live Stream</a></p>
                </div>
            </div>
        </>
    )
}

export default LivestreamSection;
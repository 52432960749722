import React, { Component } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import {
  one,
  two,
  three,
  four,
  five,
  six,
  seven,
  eight,
  nine,
  ten,
  eleven,
  twelve,
  thirteen,
  fourteen,
  fifteen,
} from './images.js';

const Carousel = () => {

  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToScroll: 1,
    centerMode: true,
    autoplay: true,
    variableWidth: true,
    centerPadding: '20px'
  };

  const alt = 'Billy Kametz';
  const images = [
    {
      imageSrc: two,
      title: alt,
    },
    {
      imageSrc: one,
      title: alt,
    },
    {
      imageSrc: three,
      title: alt,
    },
    {
      imageSrc: seven,
      title: alt,
    },
    {
      imageSrc: eight,
      title: alt,
    },
    {
      imageSrc: nine,
      title: alt,
    },
    {
      imageSrc: ten,
      title: alt,
    },
    {
      imageSrc: four,
      title: alt,
    },
    {
      imageSrc: five,
      title: alt,
    },
    {
      imageSrc: six,
      title: alt,
    },
    {
      imageSrc: eleven,
      title: alt,
    },
    {
      imageSrc: twelve,
      title: alt,
    },
    {
      imageSrc: thirteen,
      title: alt,
    },
    {
      imageSrc: fourteen,
      title: alt,
    },
    {
      imageSrc: fifteen,
      title: alt,
    },
  ]

  return (
    <>
      <div className='mt-5 slider'>
        <Slider {...settings}>
          {images.map((image, index) => (
            <div key={index} className='slide'>
              <div>
                <img alt={image.title} src={image.imageSrc} />
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </>
  )
}

export default Carousel;
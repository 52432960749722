import React, { Component } from 'react';
import billyImage from '../assets/billy_kametz2.jpg';

const Header = () => {
    return (
        <header>
            <div className="container px-lg-5">
                <div className="row gx-lg-5 align-items-center">
                    <div className="col-xl-5 offset-xl-1 col-lg-6">
                        <div>
                            <h1 className="display-3 header-title text-center">
                                <span className="name">Billy Kametz</span>
                                <span className="number">120</span>
                                <span className="star">Star Challenge</span>
                            </h1>

                            <div className="mt-5 subtitle">
                                <h4>Saturday May 25th, 2024</h4>
                                <h5>10am - 10pm EST</h5>
                            </div>
                            <div className="mt-5 subtitle text-muted">
                                <h6 className='text-uppercase'>#BillyKametz #120StarChallenge</h6>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-5 col-xl-4">
                        <div className="d-flex justify-content-center mt-2 mt-xxl-0">
                            <div className="profile">
                                <img className="profile-img" src={billyImage}
                                    alt="Billy Kametz 120 Star Challenge" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header;